<!-- eslint-disable -->
<template>
  <v-app id="home">
    <home-header />
    <!-- <home-bar /> -->
    <div class="main-content">
      <div style="background-color:#182983;height:80px"></div>
      <v-row align="center" justify="center" style="background-color:#182983">
        <h1 class="" style="color:#fded08">TLM EXPRESS</h1>
      </v-row>
      <v-row align="center" justify="center" style="background-color:#182983">
        <h1 class="" style="color:#fded08">CONTACTER NOUS</h1>
      </v-row>
      <v-row style="margin-top:20px;justify-content:center">
        <div class="form-container">
          <b-form @submit.prevent="submitMessage">
            <label for="fname">Nom et Préonm</label>
            <input required v-model="fname" class="input-class" type="text" id="fname" name="firstname" placeholder="Votre Prénom">
            <label for="email">Email</label>
            <input required v-model="email" class="input-class" type="text" id="lname" name="lastname" placeholder="Votre Email">
            <label for="phone">N° Téléphone</label>
            <input v-model="phone" class="input-class" type="text" id="lname" name="lastname" placeholder="Votre N° Téléphone">
            <label for="country">Wilaya</label>
            <select required v-model="selectedWilaya">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
            </select>
            <label for="subject">Sujet</label>
            <textarea required v-model="subject" id="subject" name="subject" placeholder="Votre sujet" style="height:200px"></textarea>
            <input class="input-class" type="submit" value="Submit"> <span v-if="showMessage == true" style="float:right;color:green">Votre message à été envoyé avec success</span>
          </b-form>
        </div>

      </v-row>
    </div>
      <div style="height:150px"></div>
      <home-footer/>
  </v-app>
</template>

<script>
/* eslint-disable */
  import HomeBar from './components/core/HomeBar.vue'
  import HomeHeader from './HomeHeader.vue';
  import axios from 'axios'
  export default {
    name: 'AboutUs',
    components: {
      HomeHeader,
      HomeBar,
      HomeFooter: () => import('./components/core/HomeFooter'),
    },
    data() {
      return {
        selectedWilaya: {
          name:null,
          communues: []
        },
        showMessage:false,
        selectedCommunue: null,
        trackingMessage: {
          show: false,
          status: null,
          message: null
        },
        loginMessage: {
          show: false,
          status: null,
          message: null
        },
        tracking_id: null,
        showPackageModal: false,
        messageWhenNoItems: 'There are not items',
        timelineItems: [
          // {
          //   from: new Date(2018, 7,1),
          //   title: 'Name',
          //   description:
          //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
          // },
        ],
        messagereseved: null,
        cemail: null,
        cname: null,
        telephone: null,
        text: null,
        emailexist: null,
        semail: null,
        spassword: null,
        name: null,
        address: null,
        phone: null,
        wilaya: null,
        communue: null,
        email: null,
        auth_token: null,
        password: null,
        hover1: false,
        hover2: false,
        hover3: false,
        stand1: false,
        stand2: false,
        stand3: false,
        colClass: '',
        colClass2: '',
        postCenters: null,
        selectedPost: 'ANB',
        wilayas: [],
        fname:null,
        phone:null,
        email:null,
        subject:null
      }
    },
    mounted() {
      this.getWilayas()
    },
    methods: {
      getWilayas() {
        axios.post('get-wilayas').then(response => {
          if (response.data.status == 'success') {
            this.wilayas = response.data.data
            let wilayas = []
            this.wilayas.forEach(element => {
              wilayas.push(element.name)
            });
            this.$set(this.columns[2].filterOptions, 'filterDropdownItems', wilayas);
          }
        })
      },
      submitMessage() {
        axios.post('submit-message', {
          subject:this.subject,
          fname:this.fname,
          email:this.email,
          phone:this.phone,
          wilaya:this.selectedWilaya
        }).then(response => {
          if (response.data.status == "success") {
            this.showMessage = true
            this.selectedWilaya=null
            this.fname=null
            this.email=null
            this.phone=null
            this.subject=null
          }
        })
      }
    }
  }
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}
.v-application--wrap {
    min-height: 100vh;
    justify-content: center;
}
.container {
  text-align: center;
}
.main-content {
}
#home {
  background-color: white;
}
.input-class[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.input-class[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.input-class[type=submit]:hover {
  background-color: #45a049;
}

.form-container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
</style>